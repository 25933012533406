<template>
    <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 100vh;">
        <b-container>
            <b-row class="justify-content-md-center">
                <b-col md="8" lg="6" xl="4">
                    <div class="text-center mb-3">
                        <img :src="logoUrl" alt="Logo" style="max-width: 250px;" />
                    </div>
                    <b-card>
                        <h4 class="text-center mb-3">Register</h4>
                        <b-form @submit.prevent="handleRegister">

                            <b-form-group label="Username" label-for="register-username">
                                <b-form-input id="register-username" type="text" v-model="registerData.username" required
                                    placeholder="Enter username"></b-form-input>
                            </b-form-group>

                            <b-form-group label="Email" label-for="register-email">
                                <b-form-input id="register-email" type="email" v-model="registerData.email" required
                                    placeholder="Enter email"></b-form-input>
                            </b-form-group>

                            <b-form-group label="Password" label-for="register-password">
                                <b-form-input id="register-password" type="password" v-model="registerData.password"
                                    required placeholder="Enter password"></b-form-input>
                            </b-form-group>

                            <div class="text-center">
                                <b-button :disabled="isSubmitting" type="submit" class="w-100 mt-3">
                                    Register
                                </b-button>
                            </div>
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
    name: 'RegisterComponent',
    data() {
        return {
            registerData: {
                username: '',
                email: '',
                password: ''
            },
            isSubmitting: false,
            logoUrl: require('@/assets/logo.png'),
        };
    },
    methods: {
        handleRegister() {
            this.isSubmitting = true;
            axios.post('https://localhost:7036/api/auth/register', this.registerData)
                .then(response => {
                    console.log(response.data);
                })
                .catch(error => {
                    console.error('Registration failed:', error);
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        },
    }
};
</script>