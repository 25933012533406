<template>
    <div class="qlik-master-date">
        <b-container fluid>
            <notification-component ref="notification" />
            <b-row>
                <b-col cols="8">
                    <h4 class="heading">Manage Dates</h4>
                </b-col>
                <b-col cols="4">
                    <b-button @click="showModal(true)" class="btn-right">+ Add New</b-button>
                </b-col>
            </b-row>
            <hr>
            <b-table striped hover :items="paginatedData" @row-clicked="showModal(false, $event)" :row-class="rowClass">
                <template v-slot:cell(dateFrom)="data">
                    {{ formatDate(data.item.dateFrom) }}
                </template>

                <template v-slot:cell(dateTo)="data">
                    {{ formatDate(data.item.dateTo) }}
                </template>

                <template v-slot:cell(isCurrent)="data">
                    <span
                        v-html="data.item.isCurrent == true ? '<span style=\'color: red; font-weight: bold;\'>Yes</span>' : 'No'"></span>
                </template>

                <template v-slot:cell(actions)="data">
                    <b-icon name="trash" variant="danger" @click.stop="confirmDelete(data.item)"></b-icon>
                </template>
            </b-table>

            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="itemsPerPage" @change="handlePageChange"
                aria-controls="my-table" class="my-3 d-flex justify-content-center" variant="secondary"></b-pagination>

            <b-modal v-model="isModalVisible" ok-only @hide="resetFormData" hide-footer>
                <template #modal-title>
                    {{ editing ? 'Edit' : 'Add' }} Date
                </template>
                <b-form @submit.prevent="handleSubmit" novalidate>
                    <b-form-group label="Report Month:" label-for="input-report-month" :state="formValidity.reportMonth">
                        <month-picker @selectedMonth="onSelectMonth" :modelValue="formData.reportMonth"></month-picker>
                        <span v-if="formValidity.reportMonth === false" class="text-danger">
                            Please select a valid report month.
                        </span>
                    </b-form-group>

                    <!-- Date From Field -->
                    <b-form-group label="Date From:" label-for="input-date-from">
                        <b-form-input id="input-date-from" v-model="formData.dateFrom" type="date"
                            placeholder="Select start date" :disabled=true></b-form-input>
                    </b-form-group>

                    <!-- Date To Field -->
                    <b-form-group label="Date To:" label-for="input-date-to">
                        <b-form-input id="input-date-to" v-model="formData.dateTo" type="date" placeholder="Select end date"
                            :disabled=true></b-form-input>
                    </b-form-group>

                    <!-- Working Days Field -->
                    <b-form-group label="Working Days:" label-for="input-working-days" :state="formValidity.workingDays">
                        <b-form-input id="input-working-days" v-model="formData.workingDays" type="number"
                            placeholder="Enter number of working days"></b-form-input>
                        <span v-if="formValidity.workingDays === false" class="text-danger">
                            Working days must be a positive number.
                        </span>
                    </b-form-group>

                    <!-- Is Current Field -->
                    <b-form-group label="Is Current Month:" label-for="input-is-current">
                        <b-form-checkbox id="input-is-current" v-model="formData.isCurrent">
                        </b-form-checkbox>
                    </b-form-group>


                    <!-- Submit Button -->
                    <hr>
                    <b-button type="submit" class="btn-right">
                        Save
                    </b-button>
                </b-form>
            </b-modal>
        </b-container>
    </div>
</template>

<script>
import {
    getQlikMasterDates,
    createQlikMasterDate,
    updateQlikMasterDate,
    deleteQlikMasterDate
} from '@/apiService';

import MonthPicker from './MonthPicker.vue';
import NotificationComponent from '../components/NotificationComponent.vue';

export default {
    name: 'QlikMasterDateComponent',
    components: {
        NotificationComponent,
        MonthPicker
    },
    data() {
        return {
            formData: {
                reportMonth: '',
                dateFrom: null,
                dateTo: null,
                workingDays: null,
                isCurrent: false
            },
            formValidity: {
                reportMonth: null,
                workingDays: null,
            },
            masterDateList: [],
            isModalVisible: false,
            editing: false,
            currentPage: 1,
            totalRows: 1,
            itemsPerPage: 20
        };
    },
    computed: {
        paginatedData() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.masterDateList.slice(start, end);
        }
    },
    created() {
        this.fetchAllData();
    },
    methods: {
        fetchAllData() {
            getQlikMasterDates()
                .then(data => {
                    this.totalRows = data.length;
                    this.masterDateList = data.sort((a, b) => {
                        return new Date(b.reportMonth) - new Date(a.reportMonth);
                    });
                })
                .catch(() => {
                    this.$refs.notification.showError('There was an error fetching dates', 3000);

                });
        },
        onSelectMonth(newValue) {
            if (newValue) {
                this.formData.reportMonth = newValue;
                this.updateDateRange(newValue);
            } else {
                this.formData.dateFrom = '';
                this.formData.dateTo = '';
            }
        },
        formatDate(dateString) {
            if (!dateString) return '';
            const date = new Date(dateString);
            date.setHours(date.getHours() + 2);
            return date.toISOString().split('T')[0];
        },
        updateDateRange(monthYear) {
            const [year, month] = monthYear.split('-').map(Number);
            // Set to noon to avoid timezone affecting the date
            const startDate = new Date(Date.UTC(year, month - 1, 1, 12));
            // Set to noon on the last day of the month
            const endDate = new Date(Date.UTC(year, month, 0, 12));
            this.formData.dateFrom = startDate.toISOString().split('T')[0];
            this.formData.dateTo = endDate.toISOString().split('T')[0];
        },
        rowClass(item, type) {
            if (type === 'row' && item.isCurrent) {
                return 'highlight-current';
            }
        },
        handlePageChange(page) {
            this.currentPage = page;
        },
        showModal(addNew, item = null) {
            this.isModalVisible = true;
            if (addNew) {
                this.editing = false;
                this.resetFormData();
            } else {
                this.editing = true;
                this.formData = { ...item };
                this.formData.dateFrom = this.formatDate(item.dateFrom)
                this.formData.dateTo = this.formatDate(item.dateTo)
            }
        },
        validateForm() {
            let isValid = true;

            if (!this.formData.reportMonth) {
                this.formValidity.reportMonth = false;
                isValid = false;
            } else {
                this.formValidity.reportMonth = true;
            }

            if (!this.formData.workingDays || this.formData.workingDays <= 0) {
                this.formValidity.workingDays = false;
                isValid = false;
            } else {
                this.formValidity.workingDays = true;
            }

            return isValid;
        },
        handleSubmit() {
            if (this.validateForm()) {
                const action = this.editing
                    ? updateQlikMasterDate(this.formData.reportMonth, this.formData)
                    : createQlikMasterDate(this.formData);

                action
                    .then(() => {
                        this.isModalVisible = false;
                        this.fetchAllData();
                        if (!this.editing) {
                            this.$refs.notification.showSuccess('Date added successfully', 3000);
                        } else {
                            this.$refs.notification.showSuccess('Date updated successfully', 3000);
                        }
                    })
                    .catch(() => {
                        this.$refs.notification.showSuccess('There was an error submitting date', 3000);
                    });
            }
        },
        confirmDelete(item) {
            if (window.confirm(`Are you sure you want to delete the date for ${item.reportMonth}?`)) {
                this.deleteItem(item);
            }
        },
        deleteItem(item) {
            deleteQlikMasterDate(item.reportMonth)
                .then(() => {
                    this.fetchAllData();
                })
                .catch(error => {
                    console.error('There was an error deleting the item:', error);
                });
        },
        resetFormData() {
            this.formData = {
                reportMonth: '',
                dateFrom: null,
                dateTo: null,
                workingDays: null,
                isCurrent: false
            };
        }
    }
};
</script>
  
<style scoped>
.btn-right {
    float: right;
}

.heading {
    float: left;
}

.b-pagination .page-link {
    background-color: var(--bs-secondary);
    border-color: var(--bs-secondary);
}

.b-pagination .page-item.active .page-link {
    background-color: var(--bs-secondary-dark);
    border-color: var(--bs-secondary-dark);
}

.highlight-current {
    background-color: #ff0000 !important;
}

.custom-control-label {
    margin-left: 8px;
}

.b-pagination .page-item.active .page-link, .page-link {
  background-color: white !important;
  border-color: #bbb !important;
  color: black !important;
}

.b-pagination .page-item.active .page-link {
  border-color: #D20606 !important;
  color: #D20606 !important;
}
</style>

  