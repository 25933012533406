<template>
    <div class="d-flex flex-column align-items-center justify-content-center" style="min-height: 100vh;">
        <notification-component ref="notification" />
        <b-container>
            <b-row class="justify-content-md-center">
                <b-col md="8" lg="6" xl="6">
                    <div class="text-center mb-3">
                        <img :src="logoUrl" alt="Logo" style="max-width: 250px;" />
                    </div>
                    <b-card>
                        <h4 class="text-center mb-3">Login</h4>
                        <b-form @submit.prevent="handleLogin">
                            <b-form-group label="Username" label-for="login-username">
                                <b-form-input id="login-username" v-model="loginData.username" required
                                    placeholder="Enter username"></b-form-input>
                            </b-form-group>

                            <b-form-group label="Password" label-for="login-password">
                                <b-form-input id="login-password" type="password" v-model="loginData.password" required
                                    placeholder="Enter password"></b-form-input>
                            </b-form-group>

                            <div class="text-center">
                                <b-button type="submit" class="w-100 mt-3">Login</b-button>
                            </div>
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Cookies from 'js-cookie';
import NotificationComponent from '../components/NotificationComponent.vue';
import { loginUser } from '@/apiService';

export default {
    name: 'LoginComponent',
    components: {
        NotificationComponent
    },
    data() {
        return {
            loginData: {
                username: '',
                password: ''
            },
            logoUrl: require('@/assets/logo.png'),
        };
    },
    methods: {
        ...mapActions(['login']),
        handleLogin() {
            loginUser(this.loginData)
                .then(response => {
                    const token = response.token;
                    const expiration = response.expiration;

                    const expirationDate = new Date(expiration);
                    const currentDate = new Date();
                    const expiresInDays = (expirationDate - currentDate) / (1000 * 60 * 60 * 24);

                    Cookies.set('token', token, { expires: expiresInDays });

                    this.login(true).then(() => {
                        this.$router.push('/qlik-master-dates');
                    });

                    // Production environment with HTTPS
                    // Cookies.set('token', token, { expires: expiresInDays, secure: true });

                    // const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin') || '/default-route';
                    // this.$router.push(redirectAfterLogin);
                    // sessionStorage.removeItem('redirectAfterLogin');
                })
                .catch(error => {
                    if (error?.response.data.status == 401) {
                        this.$refs.notification.showError("Incorrect login credentials.", 3000);
                    } else {
                        this.$refs.notification.showError('Login failed.', 3000);
                    }
                });
        },
    }
};
</script>