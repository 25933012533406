import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../public/config.json';

const environment = process.env.NODE_ENV;

let BASE_URL = config.default.BASE_URL;
if (config[environment]) {
    BASE_URL = config[environment].BASE_URL;
}

console.log(BASE_URL);

const ORYX_MASTER_DATA_ENDPOINT = `${BASE_URL}/OryxMasterData`;
const QLIK_MASTER_DATE_ENDPOINT = `${BASE_URL}/QlikMasterDate`;
const AUTH_ENDPOINT = `${BASE_URL}/Auth`;

const getAuthHeader = () => {
    const token = Cookies.get('token');
    if (!token) {
        throw new Error('No token found');
    }
    return { Authorization: `Bearer ${token}` };
};

const getUsers = () => {
    try {
        const headers = getAuthHeader();
        return axios.get(`${AUTH_ENDPOINT}/users`, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const loginUser = (data) => {
    try {
        return axios.post(`${AUTH_ENDPOINT}/login`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const createUser = (data) => {
    try {
        const headers = getAuthHeader();
        return axios.post(`${AUTH_ENDPOINT}/register`, data, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const updateUser = (userId, data) => {
    try {
        const headers = getAuthHeader();
        return axios.post(`${AUTH_ENDPOINT}/update/${userId}`, data, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const getMasterData = () => {
    try {
        const headers = getAuthHeader();
        return axios.get(ORYX_MASTER_DATA_ENDPOINT, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const getMasterDataByReportMonth = (reportMonth) => {
    try {
        const headers = getAuthHeader();
        return axios.get(`${ORYX_MASTER_DATA_ENDPOINT}/${reportMonth}`, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const createMasterData = (data) => {
    try {
        const headers = getAuthHeader();
        return axios.post(ORYX_MASTER_DATA_ENDPOINT, data, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const copyMasterData = (data) => {
    try {
        const headers = getAuthHeader();
        return axios.post(`${ORYX_MASTER_DATA_ENDPOINT}/AddBulk`, data, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const updateMasterData = (reportMonth, fleetNumber, data) => {
    try {
        const headers = getAuthHeader();
        return axios.post(`${ORYX_MASTER_DATA_ENDPOINT}/${reportMonth}/${fleetNumber}`, data, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const deleteMasterData = (reportMonth, fleetNumber) => {
    try {
        const headers = getAuthHeader();
        return axios.get(`${ORYX_MASTER_DATA_ENDPOINT}/delete/${reportMonth}/${fleetNumber}`, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const getQlikMasterDates = () => {
    try {
        const headers = getAuthHeader();
        return axios.get(QLIK_MASTER_DATE_ENDPOINT, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const createQlikMasterDate = (data) => {
    try {
        const headers = getAuthHeader();
        return axios.post(QLIK_MASTER_DATE_ENDPOINT, data, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const updateQlikMasterDate = (reportMonth, data) => {
    try {
        const headers = getAuthHeader();
        return axios.post(`${QLIK_MASTER_DATE_ENDPOINT}/${reportMonth}`, data, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

const deleteQlikMasterDate = (reportMonth) => {
    try {
        const headers = getAuthHeader();
        return axios.delete(`${QLIK_MASTER_DATE_ENDPOINT}/${reportMonth}`, { headers })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error));
    } catch (error) {
        return Promise.reject(error);
    }
};

export {
    getUsers,
    getMasterData,
    getMasterDataByReportMonth,
    createMasterData,
    copyMasterData,
    updateMasterData,
    deleteMasterData,
    getQlikMasterDates,
    createQlikMasterDate,
    updateQlikMasterDate,
    deleteQlikMasterDate,
    updateUser,
    loginUser,
    createUser
};