<template>
    <div class="input-group">
        <input ref="monthInput" type="text" class="form-control" placeholder="Select month and year" />
        <button v-if="currentValue" class="input-group-text close-button" @click="clear">
            <i class="bi bi-x-circle"></i>
        </button>
    </div>
</template>
  
<script>
import Flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default {
    name: 'MonthPicker',
    emits: ['update:modelValue'],
    data() {
        return {
            currentValue: this.modelValue,
        };
    },
    props: {
        modelValue: {
            type: String,
            default: ''
        }
    },
    flatpickrInstance: null,
    mounted() {
        this.initializeFlatpickr();
    },
    beforeDestroy() {
        if (this.flatpickrInstance) {
            this.flatpickrInstance.destroy();
        }
    },
    watch: {
        modelValue(newVal) {
            if (this.flatpickrInstance) {
                this.flatpickrInstance.setDate(newVal, false);
            }
        }
    },
    methods: {
        clear() {
            if (this.flatpickrInstance) {
                this.flatpickrInstance.clear();
                this.currentValue = '';
                this.$emit('selectedMonth', null);
            }
        },
        initializeFlatpickr() {
            this.flatpickrInstance = new Flatpickr(this.$refs.monthInput, {
                plugins: [
                    new monthSelectPlugin({
                        shorthand: true,
                        dateFormat: "Y-m",
                        altInput: true,
                        altFormat: "F Y",
                    })
                ],
                onChange: this.handleDateChange,
            });

            if (this.modelValue) {
                this.flatpickrInstance.setDate(this.modelValue, false);
            }
        },
        handleDateChange(selectedDates, dateStr) {
            this.currentValue = dateStr;
            this.$emit('selectedMonth', dateStr);
        }
    }
};
</script>
  
<style>
.flatpickr-rContainer {
    width: 100% !important;
}

.flatpickr-monthSelect-month {
    width: 100% !important;
    display: block;
}

.today {
    color: red !important;
    font-weight: bold;
}

.close-button {
  border-color: rgb(222, 230, 226) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
</style>  