import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isLoggedIn: false,
    },
    mutations: {
        setLoginStatus(state, status) {
            state.isLoggedIn = status;
        },
    },
    actions: {
        login({ commit }) {
            return new Promise(resolve => {
                const token = Cookies.get('token');
                commit('setLoginStatus', !!token);
                resolve();
            });
        },
        logout({ commit }) {
            return new Promise(resolve => {
                Cookies.remove('token');
                commit('setLoginStatus', false);
                resolve();
            });
        }
    }
});

// Check the login status on application start
store.dispatch('login');

export default store;