<template>
  <div id="app">
    <SidebarComponent v-if="isLoggedIn"/>
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SidebarComponent from './components/SidebarComponent';

export default {
  name: 'App',
  components: {
    SidebarComponent
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.isLoggedIn
    })
  }
}
</script>

<style>
#app {
  display: flex;
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-content {
  flex-grow: 1;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  #app {
    flex-direction: column;
  }
  .main-content {
    margin-top: 20px;
  }
}
</style>