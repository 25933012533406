<template>
    <transition name="fade">
      <div v-if="isVisible" class="notification" :class="`notification-${type}`">
        {{ message }}
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    name: 'NotificationComponent',
    data() {
      return {
        isVisible: false,
        message: '',
        type: 'info',
        timeout: null,
      };
    },
    methods: {
      show(message, type = 'info', duration = 3000) {
        this.isVisible = true;
        this.message = message;
        this.type = type;
        this.clearTimeout();
        this.timeout = setTimeout(this.hide, duration);
      },
      showSuccess(message, duration) {
        this.show(message, 'success', duration);
      },
      showInfo(message, duration) {
        this.show(message, 'info', duration);
      },
      showWarning(message, duration) {
        this.show(message, 'warning', duration);
      },
      showError(message, duration) {
        this.show(message, 'error', duration);
      },
      hide() {
        this.isVisible = false;
      },
      clearTimeout() {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
      }
    },
    beforeDestroy() {
      this.clearTimeout();
    }
  };
  </script>
  
  <style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  
  .notification {
    padding: 10px;
    border-radius: 4px;
    color: #fff;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 5000;
  }
  .notification-info {
    background-color: #2196f3;
  }
  .notification-success {
    background-color: #48944a;
  }
  .notification-warning {
    background-color: #ff9800;
  }
  .notification-error {
    background-color: #ff685e;
  }
  </style>
  