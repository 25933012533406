import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import RegisterComponent from './components/RegisterComponent.vue';
import LoginComponent from './components/LoginComponent.vue';
import OryxMasterDataComponent from './components/OryxMasterDataComponent.vue';
import QlikMasterDateComponent from './components/QlikMasterDateComponent.vue';
import UserComponent from './components/UserComponent.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/oryx-master-data' },
  { path: '/register', component: RegisterComponent },
  { path: '/login', component: LoginComponent },
  {
    path: '/oryx-master-data',
    component: OryxMasterDataComponent,
    meta: { requiresAuth: true }
  },
  {
    path: '/qlik-master-dates',
    component: QlikMasterDateComponent,
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    component: UserComponent,
    meta: { requiresAuth: true }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production'
  ? '/oryxadmin/'
  : '/',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path === '/login' && store.state.isLoggedIn) {
    store.dispatch('logout').then(() => {
      next();
    });
  } else if (to.path === '/logout') {
    store.dispatch('logout').then(() => {
      next('/login');
    });
  } else if (to.matched.some(record => record.meta.requiresAuth) && !store.state.isLoggedIn) {
    sessionStorage.setItem('redirectAfterLogin', to.fullPath);
    next('/login');
  } else {
    next();
  }
});

export default router;