<template>
  <div class="sidebar">
    <div class="text-center mb-3">
      <img :src="logoUrl" alt="Logo" style="max-width: 150px;" />
    </div>
    <hr>
    <nav>
      <ul>
        <li><router-link to="/oryx-master-data">Fleet</router-link></li>
        <li><router-link to="/qlik-master-dates">Dates</router-link></li>
        <li><router-link to="/users">Users</router-link></li>
        <li><router-link to="/logout">Logout</router-link></li>
      </ul>
    </nav>
  </div>
</template>
  
<script>
export default {
  name: 'SidebarComponent',
  data() {
    return {
      logoUrl: require('@/assets/logo.png'),
    };
  },
}
</script>
  
<style scoped>
.sidebar {
  width: 250px;
  background-color: #f0f0f0;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  height: 100vh;
  box-sizing: border-box;
}

.sidebar h4 {
  margin-top: 0;
  color: #D20606;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav li {
  margin-bottom: 10px;
}

nav li a {
  text-decoration: none;
  color: #2c3e50;
}

nav li a.router-link-exact-active {
  color: #D20606;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    box-shadow: none;
    padding-bottom: 10px;
  }
}
</style>  