<template>
  <div class="oryx-master-data">
    <b-container fluid>
      <notification-component ref="notification" />
      <b-row>
        <b-col cols="8">
          <h4 class="heading">Manage Fleet</h4>
        </b-col>
        <b-col cols="4" class="btn-right">
          <b-button @click="showModal(true)" class="btn-right">+ Add New</b-button>
          <b-button @click="showCopyModal" class="btn-right" style="margin-right: 10px;">Copy Fleet</b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="4" style="text-align: left;">
          <b-input-group>
            <b-form-group label="Report Month" label-for="reportMonthFilter" class="reportMonthFilter">
              <month-picker @selectedMonth="onSelectReportMonthFilter" :modelValue="reportMonthFilter"></month-picker>
            </b-form-group>
          </b-input-group>
        </b-col>
        <b-col cols="4" style="text-align: left;">
          <label>Payload</label>
          <b-input-group>
            <b-form-input v-model="payloadFilter"></b-form-input>
            <b-input-group-append v-if="payloadFilter">
              <b-button variant="outline-secondary" class="close-button" style="" @click="clearPayloadFilter">
                <b-icon icon="x-circle"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <!-- Delete Modal -->
      <b-modal id="deleteModal" v-model="isDeleteModalVisible" title="Confirm Delete" size="lg">
        <p v-if="fleetToDelete">Are you sure you want to delete [<b>Report Month:</b> {{ fleetToDelete.reportMonth }} <b>Fleet Number: </b>{{ fleetToDelete.fleetNumber }}].</p>
        <template v-slot:modal-footer>
          <b-button @click="deleteFleet()" :disabled="isSubmitInProgress">Confirm</b-button>
          <b-button variant="danger" @click="isDeleteModalVisible = false; fleetToDelete = null" :disabled="isSubmitInProgress">Cancel</b-button>
        </template>
      </b-modal>

      <!-- Copy Modal -->
      <b-modal id="copyModal" v-model="isCopyModalVisible" title="Copy Report Month Data" size="lg">
        <template v-slot:modal-header>
          <h5 class="modal-title">Select Report Month to Copy</h5>
        </template>
        <b-form-group label="Select Copy Month:" label-for="copy-report-month">
          <month-picker @selectedMonth="onSelectCopyMonth"></month-picker>
        </b-form-group>

        <b-form-group v-if="selectedCopyMonth && itemsForSelectedMonth.length > 0" label="Select Report Month:"
          label-for="report-month">
          <month-picker @selectedMonth="onSelectMonth"></month-picker>
        </b-form-group>

        <div v-if="itemsForSelectedMonth.length > 0">
          <b-table striped hover :items="paginatedDataCopy">
          </b-table>
          <!-- Pagination for itemsForSelectedMonth -->
          <b-pagination v-model="currentPageCopy" :total-rows="totalRowsCopy" :per-page="itemsPerPageCopy"
            @change="handlePageChangeCopy" aria-controls="copy-table" class="my-3  justify-content-center"></b-pagination>
        </div>
        <div v-if="itemsForSelectedMonth.length == 0 && selectedCopyMonth" class="mt-3">
          <p>No items to display for the selected month: {{ selectedCopyMonth }}.</p>
        </div>

        <!-- Actions -->
        <template v-slot:modal-footer>
          <b-button v-if="selectedMonth && selectedCopyMonth && itemsForSelectedMonth.length > 0"
            @click="copyMasterData">Copy Data</b-button>
          <b-button variant="danger" @click="isCopyModalVisible = false; clearCopyForm()">Cancel</b-button>
        </template>
      </b-modal>
      <hr>
      <b-table  label-sort-asc=""
        label-sort-desc=""
        label-sort-clear=""
        striped hover sort-icon-left :items="paginatedData" :fields="tableColumns">
        <template #cell(actions)="row">
          <b-icon icon="pencil" style="margin-right: 10px;" @click="showModal(false, row.item)"></b-icon>
          <b-icon icon="trash" @click="isDeleteModalVisible = true; fleetToDelete = row.item"></b-icon>
        </template>
      </b-table>

      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="itemsPerPage" @change="handlePageChange"
        aria-controls="my-table" class="my-3 d-flex justify-content-center" variant="secondary"></b-pagination>

      <b-modal v-model="isModalVisible" @hide="resetFormData">
        <template #modal-title>
          {{ editing ? 'Edit' : 'Add' }} Data
        </template>
        <b-form @submit.prevent="handleSubmit">
          <!-- Report Month Field -->
          <b-form-group label="Report Month:" label-for="input-report-month">
            <month-picker @selectedMonth="onSelectMonth" :modelValue="formData.reportMonth"></month-picker>
            <span class="text-danger" v-if="formValidity.reportMonth">{{ formValidity.reportMonth }}</span>
          </b-form-group>

          <!-- Fleet Number Field -->
          <b-form-group label="Fleet Number:" label-for="input-fleet-number">
            <b-form-input id="input-fleet-number" v-model="formData.fleetNumber" required
              placeholder="Enter fleet number"></b-form-input>
            <span class="text-danger" v-if="formValidity.fleetNumber">{{ formValidity.fleetNumber }}</span>
          </b-form-group>

          <!-- Days in Month Field -->
          <b-form-group label="Days in Month:" label-for="input-days-in-month">
            <b-form-input id="input-days-in-month" v-model="formData.daysinMonth" type="number" required
              placeholder="Enter number of days in month"></b-form-input>
            <span class="text-danger" v-if="formValidity.daysinMonth">{{ formValidity.daysinMonth }}</span>
          </b-form-group>

          <!-- Fix Rate Field -->
          <b-form-group label="Fix Rate:" label-for="input-fix-rate">
            <b-form-input id="input-fix-rate" v-model="formData.fixRate" type="number" step="0.01" required
              placeholder="Enter fix rate"></b-form-input>
            <span class="text-danger" v-if="formValidity.fixRate">{{ formValidity.fixRate }}</span>
          </b-form-group>

          <!-- Variable Rate Field -->
          <b-form-group label="Variable Rate:" label-for="input-variable-rate">
            <b-form-input id="input-variable-rate" v-model="formData.variableRate" type="number" step="0.01" required
              placeholder="Enter variable rate"></b-form-input>
            <span class="text-danger" v-if="formValidity.variableRate">{{ formValidity.variableRate }}</span>
          </b-form-group>

          <!-- Fixed Staff Cost Field -->
          <b-form-group label="Fixed Staff Cost:" label-for="input-fixed-staff-cost">
            <b-form-input id="input-fixed-staff-cost" v-model="formData.fixedStaffCost" type="number" step="0.01" required
              placeholder="Enter fixed staff cost"></b-form-input>
            <span class="text-danger" v-if="formValidity.fixedStaffCost">{{ formValidity.fixedStaffCost }}</span>
          </b-form-group>

          <!-- Depot Field -->
          <b-form-group label="Depot:" label-for="input-depot">
            <b-form-input id="input-depot" v-model="formData.depot" required placeholder="Enter depot"></b-form-input>
            <span class="text-danger" v-if="formValidity.depot">{{ formValidity.depot }}</span>
          </b-form-group>

          <!-- Payload Field -->
          <b-form-group label="Payload:" label-for="input-payload">
            <b-form-input id="input-payload" v-model="formData.payload" required
              placeholder="Enter payload"></b-form-input>
            <span class="text-danger" v-if="formValidity.payload">{{ formValidity.payload }}</span>
          </b-form-group>
        </b-form>
        <template #modal-footer="{ cancel }">
          <!-- Save Button -->
          <b-button @click="handleSubmit">
            {{ editing ? 'Save changes' : 'Save' }}
          </b-button>

          <!-- Cancel Button -->
          <b-button variant="danger" @click="cancel">
            Cancel
          </b-button>
        </template>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import {
  getMasterData,
  getMasterDataByReportMonth,
  createMasterData,
  copyMasterData,
  updateMasterData,
  deleteMasterData
} from '@/apiService';

import MonthPicker from './MonthPicker.vue';
import NotificationComponent from '../components/NotificationComponent.vue';
import Cookies from 'js-cookie';

export default {
  name: 'OryxMasterDataComponent',
  components: {
    NotificationComponent,
    MonthPicker
  },
  data() {
    return {
      formData: {},
      reportMonthFilter: null,
      payloadFilter: null,
      isDeleteModalVisible: false,
      formValidity: {
        reportMonth: null,
        fleetNumber: null,
        daysinMonth: null,
        fixRate: null,
        variableRate: null,
        fixedStaffCost: null,
        depot: null,
        payload: null,
      },
      tableColumns: [
        {
          label: 'Report Month',
          key: 'reportMonth',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Fleet Number',
          key: 'fleetNumber',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Variable Rate',
          key: 'variableRate',
          sortable: true,
          tdClass: ''
        },  
        {
          label: 'Fix Rate',
          key: 'fixRate',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Fixed Staff Cost',
          key: 'fixedStaffCost',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Depot',
          key: 'depot',
          sortable: true,
          tdClass: ''
        },
        {
          label: 'Payload',
          key: 'payload',
          sortable: true,
          tdClass: ''
        },  
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ],
      fleetToDelete: null,
      ogFleetItem: null,
      masterDataList: [],
      ogMasterDataList: [],
      isCopyModalVisible: false,
      selectedCopyMonth: '',
      selectedMonth: '',
      itemsForSelectedMonth: [],
      isModalVisible: false,
      editing: false,
      isSubmitInProgress: false,
      currentPage: 1,
      totalRows: 1,
      itemsPerPage: 20,
      currentPageCopy: 1,
      totalRowsCopy: 1,
      itemsPerPageCopy: 10
    };
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.masterDataList.slice(start, end);
    },
    paginatedDataCopy() {
      const startCopy = (this.currentPageCopy - 1) * this.itemsPerPageCopy;
      const endCopy = startCopy + this.itemsPerPageCopy;
      return this.itemsForSelectedMonth.slice(startCopy, endCopy);
    }
  },
  beforeMount(){
    const payloadFilter = Cookies.get('payloadFilter');
    const reportMonthFilter = Cookies.get('reportMonthFilter');

    if (payloadFilter && payloadFilter != 'null') {
      this.payloadFilter = Cookies.get('payloadFilter');
    }

    if (reportMonthFilter && reportMonthFilter != 'null') {
      this.reportMonthFilter = Cookies.get('reportMonthFilter');
    }

    this.fetchAllData();
  },
  watch: {
    payloadFilter(newVal) {
      console.log(newVal);
      Cookies.set('name', 'value');
      Cookies.set('payloadFilter', newVal);

      this.search();
    }
  },
  methods: {
    deleteFleet() {
      this.isSubmitInProgress = true;
      deleteMasterData(this.fleetToDelete.reportMonth, this.fleetToDelete.fleetNumber)
      .then(() => {
        this.isSubmitInProgress = false;

          this.fetchAllData();
          this.isDeleteModalVisible = false;
          this.fleetToDelete = null;
          this.$refs.notification.showSuccess('Fleet deleted successfully', 3000);
        })
          .catch((error) => {
            this.isSubmitInProgress = false;
            if (error?.response.data) {
              this.$refs.notification.showError(error.response.data, 3000);
            } else {
              this.$refs.notification.showError('There was an error deleting fleet', 3000);
            }
          });
      // deleteMasterData
      this.fetchAllData();
    },
    onSelectReportMonthFilter(newVal){
      this.reportMonthFilter = newVal;
      Cookies.set('reportMonthFilter', newVal);
      this.search();
    },
    search() {
      if (this.reportMonthFilter) {
        this.masterDataList = [
          ...this.ogMasterDataList.filter(item => {
            return item.reportMonth === this.reportMonthFilter;
          })
        ];
      }

      if (this.payloadFilter) {

        if (!this.reportMonthFilter) {
          this.masterDataList = [...this.ogMasterDataList];
        }

        this.masterDataList = [
          ...this.masterDataList.filter(item => {
            return item.payload === this.payloadFilter;
          })
        ];
      }

      if (!this.reportMonthFilter && !this.payloadFilter) {
        this.masterDataList = [...this.ogMasterDataList];
      }

      this.totalRows = this.masterDataList.length;

    },
    clearReportMonthFilter() {
      this.reportMonthFilter = null;
      this.search();
    },
    clearPayloadFilter() {
      this.payloadFilter = null;
      this.search();
    },
    fetchAllData() {
      getMasterData()
        .then(response => {
          this.ogMasterDataList = response.sort((a, b) => new Date(b.reportMonth) - new Date(a.reportMonth));
          this.masterDataList = [...this.ogMasterDataList];
          this.totalRows = this.ogMasterDataList.length;

          this.search();
        })
        .catch(error => {
          console.error('There was an error fetching the data:', error);
        });
    },
    getMasterDataByReportMonth() {
      getMasterDataByReportMonth(this.selectedCopyMonth)
        .then(response => {
          this.itemsForSelectedMonth = response
          this.totalRowsCopy = this.itemsForSelectedMonth.length;
        })
        .catch(error => {
          console.error('There was an error fetching the data:', error);
        });
    },
    handlePageChangeCopy(page) {
      this.currentPageCopy = page;
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    onSelectCopyMonth(newValue) {
      if (newValue) {
        if (this.isCopyModalVisible) {
          this.selectedCopyMonth = newValue;
          this.getMasterDataByReportMonth();
        }

        if (this.isModalVisible) {
          this.formData.reportMonth = newValue;
        }
      }
    },
    onSelectMonth(newValue) {
      if (newValue) {
        if (this.isCopyModalVisible) {
          this.selectedMonth = newValue;
        }

        if (this.isModalVisible) {
          this.formData.reportMonth = newValue;
        }
      }
    },
    clearCopyForm() {
      this.selectedMonth = '';
      this.selectedCopyMonth = '';
      this.itemsForSelectedMonth = [];
    },
    showCopyModal() {
      this.isCopyModalVisible = true;
    },
    showModal(addNew, item = null) {
      this.isModalVisible = true;
      this.editing = !addNew;
      if (addNew) {
        this.resetFormData();
      } else {
        this.ogFleetItem = { ...item };
        this.formData = { ...item };
      }
    },
    validateForm() {
      let isValid = true;

      // Validate reportMonth
      if (!this.formData.reportMonth) {
        this.formValidity.reportMonth = "Report month is required.";
        isValid = false;
      } else {
        this.formValidity.reportMonth = null;
      }

      // Validate daysinMonth
      if (!this.formData.daysinMonth || isNaN(this.formData.daysinMonth) || this.formData.daysinMonth <= 0) {
        this.formValidity.daysinMonth = "Days in Month must be a positive number.";
        isValid = false;
      } else {
        this.formValidity.daysinMonth = null;
      }

      // Validate fleetNumber
      if (!this.formData.fleetNumber || this.formData.fleetNumber.trim() === '') {
        this.formValidity.fleetNumber = "Fleet Number is required.";
        isValid = false;
      } else {
        this.formValidity.fleetNumber = null;
      }

      // Validate fixRate
      if (isNaN(this.formData.fixRate) || this.formData.fixRate < 0) {
        this.formValidity.fixRate = "Fix Rate must be a positive number.";
        isValid = false;
      } else {
        this.formValidity.fixRate = null;
      }

      // Validate variableRate
      if (isNaN(this.formData.variableRate) || this.formData.variableRate < 0) {
        this.formValidity.variableRate = "Variable Rate must be a positive number.";
        isValid = false;
      } else {
        this.formValidity.variableRate = null;
      }

      // Validate fixedStaffCost
      if (isNaN(this.formData.fixedStaffCost) || this.formData.fixedStaffCost < 0) {
        this.formValidity.fixedStaffCost = "Fixed Staff Cost must be a positive number.";
        isValid = false;
      } else {
        this.formValidity.fixedStaffCost = null;
      }

      // Validate depot
      if (!this.formData.depot || this.formData.depot.trim() === '') {
        this.formValidity.depot = "Depot is required.";
        isValid = false;
      } else {
        this.formValidity.depot = null;
      }

      // Validate payload
      if (!this.formData.payload || this.formData.payload.trim() === '') {
        this.formValidity.payload = "Payload is required.";
        isValid = false;
      } else {
        this.formValidity.payload = null;
      }

      return isValid;
    },
    handleSubmit() {
      if (this.validateForm()) {
        const operation = this.editing
          ? updateMasterData(this.ogFleetItem.reportMonth, this.ogFleetItem.fleetNumber, this.formData)
          : createMasterData(this.formData);

        operation.then(() => {
          this.isModalVisible = false;
          this.fetchAllData();
          if (!this.editing) {
            this.$refs.notification.showSuccess('Fleet added successfully', 3000);
          } else {
            this.$refs.notification.showSuccess('Fleet updated successfully', 3000);
          }
        })
          .catch((error) => {
            if (error?.response.data) {
              this.$refs.notification.showError(error.response.data, 3000);
            } else {
              this.$refs.notification.showError('There was an error submitting', 3000);
            }
          });
      }
    },
    copyMasterData() {
      const itemsForSelectedMonth = this.itemsForSelectedMonth.map(entry => {
        return {
          ...entry,
          reportMonth: this.selectedMonth
        };
      });
      copyMasterData(itemsForSelectedMonth).then(() => {
        this.isCopyModalVisible = false;
        this.clearCopyForm();
        this.fetchAllData();
        this.$refs.notification.showSuccess('Fleet entries copied successfully', 3000);
      })
        .catch(() => {
          this.$refs.notification.showError('There was an error copying entries', 3000);

        });
    },
    resetFormData() {
      this.formData = {};
      this.formValidity = {
        reportMonth: null,
        fleetNumber: null,
        daysinMonth: null,
        fixRate: null,
        variableRate: null,
        fixedStaffCost: null,
        depot: null,
        payload: null,
      };
      this.ogFleetItem = null;
    }
  }
};
</script>

<style>
.btn-right {
  float: right;
}

.heading {
  float: left;
}

.b-pagination .page-link {
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}

.b-pagination .page-item.active .page-link {
  background-color: var(--bs-secondary-dark);
  border-color: var(--bs-secondary-dark);
}

.reportMonthFilter {
  width: 100%;
}

.close-button {
  border-color: rgb(222, 230, 226) !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.b-pagination .page-item.active .page-link, .page-link {
  background-color: white !important;
  border-color: #bbb !important;
  color: black !important;
}

.b-pagination .page-item.active .page-link {
  border-color: #D20606 !important;
  color: #D20606 !important;
}
</style>