<template>
    <div class="user-list">
        <b-container fluid>
            <notification-component ref="notification" />
            <b-row>
                <b-col cols="8">
                    <h4 class="heading">User List</h4>
                </b-col>
                <b-col cols="4">
                    <b-button @click="showModal(true)" class="btn-right">+ Add New</b-button>
                </b-col>
            </b-row>
            <hr>
            <b-table striped hover :items="paginatedData" :fields="fields" @row-clicked="onUserClick">
                <template v-slot:cell(username)="data">
                    {{ data.item.userName }}
                </template>
                <template v-slot:cell(email)="data">
                    {{ data.item.email }}
                </template>
            </b-table>

            <b-pagination v-if="paginatedData.length > 10" v-model="currentPage" :total-rows="totalRows"
                :per-page="itemsPerPage" @change="handlePageChange" aria-controls="my-table"
                class="my-3 d-flex justify-content-center"></b-pagination>
        </b-container>

        <!-- User Modal -->
        <b-modal id="user-modal" ref="userModal" :title="isEditing ? 'Update User' : 'Create User'">
            <b-form>
                <b-form-group label="Username:" label-for="input-username">
                    <b-form-input id="input-username" v-model="form.userName" type="text" required
                        placeholder="Enter username"></b-form-input>
                </b-form-group>

                <b-form-group label="Email:" label-for="input-email">
                    <b-form-input id="input-email" v-model="form.email" type="email" required
                        placeholder="Enter email"></b-form-input>
                </b-form-group>

                <!-- Password Field -->
                <b-form-group v-if="!isEditing" label="Password:" label-for="input-password">
                    <b-form-input id="input-password" v-model="form.password" type="password" required
                        placeholder="Enter password"></b-form-input>
                </b-form-group>
            </b-form>
            <template #modal-footer>
                <!-- Save Button -->
                <b-button type="submit" @click="handleSubmit">{{ isEditing ? 'Update' : 'Create' }}</b-button>

                <!-- Cancel Button -->
                <b-button type="button" variant="danger" @click="closeModal">Cancel</b-button>
            </template>
        </b-modal>
    </div>
</template>
  
<script>
import { getUsers, createUser, updateUser } from '@/apiService';
import NotificationComponent from '../components/NotificationComponent.vue';

export default {
    name: 'UserListComponent',
    components: {
        NotificationComponent
    },
    data() {
        return {
            userList: [],
            fields: ['userName', 'email'],
            currentPage: 1,
            totalRows: 1,
            itemsPerPage: 20,
            form: {
                userName: '',
                email: ''
            },
            isEditing: false,
            editingUserId: null
        };
    },
    computed: {
        paginatedData() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.userList.slice(start, end);
        }
    },
    created() {
        this.fetchAllUsers();
    },
    methods: {
        fetchAllUsers() {
            getUsers()
                .then(data => {
                    this.totalRows = data.length;
                    this.userList = data;
                })
                .catch(error => {
                    console.error('There was an error fetching the users:', error);
                });
        },
        onUserClick(user) {
            this.showModal(false, user);
        },
        handlePageChange(page) {
            this.currentPage = page;
        },
        showModal(isNew, user = {}) {
            if (isNew) {
                this.isEditing = false;
                this.form.userName = '';
                this.form.email = '';
            } else {
                this.isEditing = true;
                this.form.userName = user.userName;
                this.form.email = user.email;
                this.editingUserId = user.id;
            }
            this.$refs.userModal.show();
        },
        closeModal() {
            this.$refs.userModal.hide();
        },
        handleSubmit() {
            const user = { ...this.form };
            if (this.isEditing) {
                updateUser(this.editingUserId, user)
                    .then(() => {
                        this.fetchAllUsers();
                        this.closeModal();
                        this.$refs.notification.showSuccess('User updated successfuly.', 3000);
                    })
                    .catch(() => {
                        this.$refs.notification.showError('There was an error updating the user', 3000);
                    });
            } else {
                createUser(user)
                    .then(() => {
                        this.fetchAllUsers();
                        this.closeModal();
                        this.$refs.notification.showSuccess('User created successfuly.', 3000);
                    })
                    .catch(() => {
                        this.$refs.notification.showError('There was an error creating the user', 3000);
                    });
            }
        }
    }
};
</script>
  
<style scoped>
.btn-right {
    float: right;
}

.heading {
    float: left;
}

.b-pagination .page-link {
    background-color: var(--bs-secondary);
    border-color: var(--bs-secondary);
}

.b-pagination .page-item.active .page-link {
    background-color: var(--bs-secondary-dark);
    border-color: var(--bs-secondary-dark);
}

.highlight-current {
    background-color: #ff0000 !important;
}

.custom-control-label {
    margin-left: 8px;
}
</style>  